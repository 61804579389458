body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-lg-4 {
  width: 33.3333333333%;
}

.col-lg-6 {
  width: 50%;
}

:root {
  box-sizing: border-box;
}

html, body, #app, #app> div {
  height: 100%;
  width: 100%;
}

.Background {
  margin: 0;
  padding: 0;
  background-image: url('/public/images/background.png'); /* Substitua pelo caminho da sua imagem de fundo */
  background-size: cover;
  min-height: 100vh;
}

.informativo {
  position: absolute;
  right: 0px; 
  top: 50%; 
  transform: translateY(-50%);
  width: 30%;
}

.QualifiersInfoMobile {
  display: none;
}

.info-divide {
  padding: 20px;
}

/* Estilo da barra de rolagem */
::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

/* Estilo da alça da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Cor da alça da barra de rolagem */
}

/* Estilo da trilha da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Cor da trilha da barra de rolagem */
}

@media only screen and (max-width: 768px) {

  .Background{
    height: 60vh;
    min-height: 60vh;
    width: 100%;
    max-width: -webkit-fill-available;
  }

  .QualifiersInfoMobile {
    display: block;
    background-color: #042235;
    color: white;
    text-align: center;
  }

  .QualifiersInfo {
    display: none;
  }

  .background_mobile {
    max-width: 100%;
  }
  
}